@import '_media-queries.scss';

// Fonts
@import url("https://fonts.googleapis.com/css?family=Libre+Franklin:300,500,600|Space+Mono:700");
$space-font: "Space Mono", monospace;
$libre-font: "Libre Franklin", sans-serif;

%space-mono {
  font-family: $space-font;
  text-transform: uppercase;
  font-weight: bold;
}

%libre-franklin {
  font-family: $libre-font;
  font-weight: 300;
  @include respond-below(sm) {
    font-size: 16px;
  }
  @include respond-above(sm) {
    font-size: 20px;
  }
}

// Colors
$white: #ffffff;
$red: #eb3d35;
$black: #141419;
$dark-gray: #5a5a5f;
$gray: #bec2c8;
$light-gray: #ebebed;
$almost-white: #f5f5f6;
$light-blue: #d9f3ff;
$blue: #26b3d9;
$green: #3ae79e;
$yellow: #deff00;
$beige: #ebe1c8;
$purple: #a45bff;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  width: 100%;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @extend %libre-franklin;
}

h1,
h2,
h3,
h4,
h5 {
  @extend %space-mono;
}

a {
  color: $black;
  text-decoration: none;
  font-weight: 500;
  position: relative;
  &::after {
    position: absolute;
    content: "";
    left: 0;
    bottom: -1px;
    width: 100%;
    height: 1px;
    transition: 30ms ease-in-out;
    background: $black;
  }
  &:hover {
    color: $red;
    &::after {
      transform: translateY(2px);
      background: $red;
    }
  }
}

span.bold, a.bold, p.bold {
  font-weight: 600;
}
