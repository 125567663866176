@import "_general.scss";

.info-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 40px 40px;
  opacity: 0;

  @include respond-below(sm) {
    justify-content: flex-start;
  }

  @include respond-above(sm) {
    position: absolute;
    top: 0;
  }

  &.show {
    opacity: 1;
    transition: opacity 0.8s ease-in-out;
  }

  .title {
    color: $green;
    text-transform: none;
    display: inline-block;
    margin-right: 20px;

    @include respond-below(sm) {
      font-size: 35px;
      position: absolute;
      top: 50px;
      z-index: 999;
    }
    @include respond-above(sm) {
      font-size: 72px;
    }
  }

  p {
    margin: 0;
    font-size: 22px;
    font-weight: bold;
  }
}

.info-box {
  width: 600px;
  max-width: 100%;
  margin: 40px 0;
  z-index: 200;
  display: flex;

  @include respond-below(sm) {
    flex-direction: column;
    margin: 0;
  }

  .info-text {
    p {
      margin-bottom: 20px;
    }

    img {
      display: none;

      &.show-if-desktop {
        width: 230px;
        float: right;
        margin-left: 20px;

        @include respond-above(sm) {
          display: inline-block;
        }
      }

      &.show-if-mobile {
        width: 100%;
        max-width: 350px;
        margin: 40px auto;

        @include respond-below(sm) {
          display: inline-block;
        }
      }
    }
  }
}
