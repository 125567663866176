@import url(https://fonts.googleapis.com/css?family=Libre+Franklin:300,500,600|Space+Mono:700);
@import url(https://fonts.googleapis.com/css?family=Libre+Franklin:300,500,600|Space+Mono:700);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@charset "UTF-8";
h1,
h2,
h3,
h4,
h5, .logo .daresay {
  font-family: "Space Mono", monospace;
  text-transform: uppercase;
  font-weight: bold; }

html,
body {
  font-family: "Libre Franklin", sans-serif;
  font-weight: 300; }
  @media (max-width: 767px) {
    html,
    body {
      font-size: 16px; } }
  @media (min-width: 768px) {
    html,
    body {
      font-size: 20px; } }

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box; }

html,
body {
  width: 100%;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

a {
  color: #141419;
  text-decoration: none;
  font-weight: 500;
  position: relative; }
  a::after {
    position: absolute;
    content: "";
    left: 0;
    bottom: -1px;
    width: 100%;
    height: 1px;
    -webkit-transition: 30ms ease-in-out;
    transition: 30ms ease-in-out;
    background: #141419; }
  a:hover {
    color: #eb3d35; }
    a:hover::after {
      -webkit-transform: translateY(2px);
              transform: translateY(2px);
      background: #eb3d35; }

span.bold, a.bold, p.bold {
  font-weight: 600; }

.page-title {
  font-size: 60px;
  line-height: 1.17;
  position: relative;
  margin-bottom: 70px; }
  .page-title::after {
    content: "";
    width: 60px;
    height: 10px;
    background: #3ae79e;
    display: block;
    position: absolute;
    bottom: -35px;
    left: 0; }

header {
  display: -webkit-flex;
  display: flex;
  position: fixed;
  height: 40px;
  width: 100%;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  color: white;
  background: #141419;
  z-index: 9999; }

.logo .daresay {
  font-size: 25px;
  margin-right: 5px; }

.logo .labs  {
  font-size: 30px; }

section {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: wrap;
          flex-flow: wrap;
  -webkit-flex: 0 0;
          flex: 0 0;
  -webkit-justify-content: space-between;
          justify-content: space-between; }

.intro {
  padding-top: 40px;
  width: 100%;
  background-image: -webkit-linear-gradient(top, #0e1a17 0%, #102318 100%);
  background-image: linear-gradient(-180deg, #0e1a17 0%, #102318 100%);
  color: #ffffff;
  position: relative; }

.offering {
  background-color: #ebebed;
  padding: 40px; }
  .offering .top,
  .offering .bottom {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto; }
  .offering .header {
    width: 100%;
    text-align: center; }
  .offering .bottom {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-flex-direction: row;
            flex-direction: row; }
    @media (max-width: 767px) {
      .offering .bottom {
        -webkit-flex-wrap: wrap;
                flex-wrap: wrap; } }
    @media (min-width: 768px) {
      .offering .bottom {
        -webkit-flex-wrap: nowrap;
                flex-wrap: nowrap;
        padding-right: 40px; } }
    .offering .bottom > div {
      width: 100%; }
      @media (max-width: 767px) {
        .offering .bottom > div {
          margin-bottom: 40px; } }
      .offering .bottom > div h2:last-of-type {
        margin-top: 30px; }
      .offering .bottom > div p {
        margin-bottom: 10px; }
      .offering .bottom > div ul {
        padding-left: 20px; }
        .offering .bottom > div ul li {
          margin-bottom: 10px; }
          .offering .bottom > div ul li:last-child {
            margin-bottom: 0; }
  .offering .report-form {
    width: 100%;
    max-width: 400px;
    margin-bottom: 40px; }
    .offering .report-form p {
      margin: 20px 0 0 !important; }
    .offering .report-form input {
      font-size: 16px;
      padding: 5px;
      margin-top: 10px;
      border: none;
      border-radius: 4px;
      width: 100%; }
      .offering .report-form input[type="submit"] {
        margin-top: 20px;
        background: #3ae79e;
        color: white;
        font-weight: bold;
        width: 200px; }
  .offering .contact {
    background: #ffffff;
    padding: 16px; }
    @media (max-width: 767px) {
      .offering .contact {
        width: 100%; } }
    @media (min-width: 768px) {
      .offering .contact {
        max-width: 400px;
        -webkit-align-self: flex-start;
                align-self: flex-start; } }
    .offering .contact .title,
    .offering .contact .email,
    .offering .contact .phone,
    .offering .contact .mission {
      margin-top: 4px;
      font-size: 15px; }
    .offering .contact .website  {
      margin-top: 32px;
      font-size: 15px;
      font-weight: 600; }
    .offering .contact img {
      max-width: 60%;
      border-radius: 100%; }

h1,
h2,
h3,
h4,
h5 {
  font-family: "Space Mono", monospace;
  text-transform: uppercase;
  font-weight: bold; }

html,
body {
  font-family: "Libre Franklin", sans-serif;
  font-weight: 300; }
  @media (max-width: 767px) {
    html,
    body {
      font-size: 16px; } }
  @media (min-width: 768px) {
    html,
    body {
      font-size: 20px; } }

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box; }

html,
body {
  width: 100%;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

a {
  color: #141419;
  text-decoration: none;
  font-weight: 500;
  position: relative; }
  a::after {
    position: absolute;
    content: "";
    left: 0;
    bottom: -1px;
    width: 100%;
    height: 1px;
    -webkit-transition: 30ms ease-in-out;
    transition: 30ms ease-in-out;
    background: #141419; }
  a:hover {
    color: #eb3d35; }
    a:hover::after {
      -webkit-transform: translateY(2px);
              transform: translateY(2px);
      background: #eb3d35; }

span.bold, a.bold, p.bold {
  font-weight: 600; }

.info-wrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  padding: 40px 40px;
  opacity: 0; }
  @media (max-width: 767px) {
    .info-wrapper {
      -webkit-justify-content: flex-start;
              justify-content: flex-start; } }
  @media (min-width: 768px) {
    .info-wrapper {
      position: absolute;
      top: 0; } }
  .info-wrapper.show {
    opacity: 1;
    -webkit-transition: opacity 0.8s ease-in-out;
    transition: opacity 0.8s ease-in-out; }
  .info-wrapper .title {
    color: #3ae79e;
    text-transform: none;
    display: inline-block;
    margin-right: 20px; }
    @media (max-width: 767px) {
      .info-wrapper .title {
        font-size: 35px;
        position: absolute;
        top: 50px;
        z-index: 999; } }
    @media (min-width: 768px) {
      .info-wrapper .title {
        font-size: 72px; } }
  .info-wrapper p {
    margin: 0;
    font-size: 22px;
    font-weight: bold; }

.info-box {
  width: 600px;
  max-width: 100%;
  margin: 40px 0;
  z-index: 200;
  display: -webkit-flex;
  display: flex; }
  @media (max-width: 767px) {
    .info-box {
      -webkit-flex-direction: column;
              flex-direction: column;
      margin: 0; } }
  .info-box .info-text p {
    margin-bottom: 20px; }
  .info-box .info-text img {
    display: none; }
    .info-box .info-text img.show-if-desktop {
      width: 230px;
      float: right;
      margin-left: 20px; }
      @media (min-width: 768px) {
        .info-box .info-text img.show-if-desktop {
          display: inline-block; } }
    .info-box .info-text img.show-if-mobile {
      width: 100%;
      max-width: 350px;
      margin: 40px auto; }
      @media (max-width: 767px) {
        .info-box .info-text img.show-if-mobile {
          display: inline-block; } }

.map-wrapper {
  height: 600px;
  position: relative; }
  @media (max-width: 767px) {
    .map-wrapper {
      height: 400px; } }
  .map-wrapper .map-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100; }

