@import '_media-queries.scss';

.map-wrapper {
  height: 600px;
  position: relative;
  
  @include respond-below(sm) {
    height: 400px;
  }

  .map-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
  }
}
